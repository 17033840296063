<template>
    <v-card
        :to="`/blog/${data.slug}`"
        rounded="0"
        outlined
        class="article"
        max-width="622">
        <img class="image" :src="data.list_image.url"/>

        <div class="d-flex align-center mt-6 mb-4">
            <article-badge :type="data.type"/>
            <!-- <div class="date">April 15, 2022</div> -->
            <div class="date">{{this.$moment(data.post_date).format('MMMM DD, YYYY')}}</div>
        </div>

        <h3 class="title mb-3">
            {{ data.title }}
        </h3>

        <div class="text mb-7">
            {{ data.short_description }}
        </div>
        <div>
            <separator/>
        </div>
    </v-card>
</template>

<script>
    import ArticleBadge from "@/components/blog/ArticleBadge"
    import Separator from "@/components/blog/Separator"
    export default {
        components: {
            ArticleBadge,
            Separator
        },
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        }
    }
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  max-height: 274px;
  object-fit: cover;
}
.article {
  border: none;
}
.date {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6B6B6B;
  }
.title {
  font-weight: 500;
  font-size: 46px !important;
  line-height: 1;
  color: #000;
}
.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #464646;
  height: 46px;

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
</style>