<template>
    <div class="page">
        <template v-if="!loading">
            <article-component v-if="articleData" :content="articleData"/>
            <div class="subscribe d-flex">
                <div class="subscribe_wrapper">
                    <div class="container d-flex">
                        <div class="subscribe_left">
                            <h2>Subscribe to get the latest articles</h2>
                            <p>Be the first to know about new features, trends and ideas</p>
                        </div>
                        <div class="subscribe_right d-flex flex-column justify-center">
                            <validation-observer v-slot="{ handleSubmit }" tag="div" class="" ref="client_email_form">
                                <v-form class="d-flex flex-column align-end" @submit.prevent="handleSubmit(submitEmail)">
                                    <validation-provider v-slot="{ errors, invalid }" tag="label" class="d-block w-full"
                                                         mode="eager"
                                                         rules="email|required" vid="email" name="Email">
                                        <p v-if="!subscribed" class="mb-2">Email Address</p>
                                        <p v-else class="mb-2">Thank you for subscribing to our newsletter!</p>
                                        <v-text-field
                                            v-model="email"
                                            type="email"
                                            height="34"
                                            dense
                                            full-width
                                            outlined
                                            hide-details
                                            :disabled="subscribed"
                                            class="rounded-0 email-input"
                                            placeholder="example@gmail.com"/>
                                        <div v-if="invalid" class="field-error-txt mt-1">
                                            {{ errors[0] }}
                                        </div>
                                    </validation-provider>

                                    <div class="d-flex justify-space-between mt-8">
                                        <v-btn
                                            :loading="loadingEmail"
                                            type="submit"
                                            outlined
                                            dark
                                            rounded
                                            :disabled="subscribed"
                                            color="#464646"
                                            class="btn-green">
                                            Subscribe
                                        </v-btn>
                                    </div>
                                </v-form>
                            </validation-observer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="container latest">
                    <separator/>
                    <h2 class="mt-6 mb-10">Latest Releases</h2>
                    <div class="list-articles mb-15">
                        <article-card v-for="item in latestArticles" :key="item.id" :data="item"/>
                    </div>
                    <div class="d-flex justify-center">
                        <v-btn
                            class="btn-green"
                            outlined
                            rounded
                            :to="{name: 'Blog'}">
                            View all posts
                        </v-btn>
                    </div>
                </div>
            </div>
        </template>
        <loader v-if="loading"/>
    </div>
</template>

<script>
    import Separator from "@/components/blog/Separator"
    import { ValidationObserver, ValidationProvider } from "vee-validate";
    import ArticleCard from "@/components/blog/ArticleCard";
    import ArticleComponent from "@/components/blog/Article"
    import Loader from "@/components/ui/Loader"
    import axios from "axios";
    export default {
        components: {
            Separator,
            ValidationObserver,
            ValidationProvider,
            ArticleCard,
            ArticleComponent,
            Loader
        },
        metaInfo() {
            if(!this.latestArticles[0]) {
                return {}
            }
            return { 
                title: this.latestArticles[0].meta_title,
                meta: [
                    { name: "description", content:  this.latestArticles[0].meta_description },
                    { property: "og:title", content: this.latestArticles[0].meta_title },
                    { property: "og:site_name", content: "UNCO" },
                    { property: "og:description", content: this.latestArticles[0].meta_description },
                    { property: "og:type", content: "website" },
                    { property: "og:url", content: this.$route.path },
                    { property: "og:image", content: this.latestArticles[0].og_image.url }       
                ]
            }
        },
        data() {
            return {
                email: "",
                subscribed: false,
                latestArticles: [],
                articleData: null,
                loading: false,
                loadingEmail: false
            }
        },
        async mounted() {
            this.loading = true
            await Promise.all([this.getLatestArticles(), this.getArticle()])
            this.loading = false
        },
        methods: {
            async submitEmail() {
                try {
                    this.loadingEmail = true
                    const { data } = await axios.post(process.env.VUE_APP_BASE_API_URL + "/subscription/subscribe", { email: this.email }); 

                    
                    this.subscribed = true

                } catch(error) {
                    console.log("error - ", error)
                    this.$refs["client_email_form"].setErrors(error.response.data.errors);
                } finally {
                    this.loadingEmail = false
                }
            },
            async getArticle() {
                try {

                    const { data } = await axios.get(process.env.VUE_APP_BASE_API_URL + `/blogs/${this.$route.params.slug}`); 

                    this.articleData = data.data
                } catch (error) {
                    console.log(error)
                }
            },
            async getLatestArticles() {
                try {
                    const params = {
                        per_page: 2,
                        page: 1
                    }
                    params[`order_by[id]`] = "DESC"

                    const { data } = await axios.get(process.env.VUE_APP_BASE_API_URL + `/blogs`, { params });
                    this.latestArticles = data.data
                    if(data.data.length) {
                        this.latestArticle = data.data[0]
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.page {
  padding-top: 78px;
  padding-bottom: 100px;
}
.section {
    padding: 0 50px;
    width: 100%;
}
.container {
    max-width: 1280px;
    padding: 0;
    height: 100%;
}
h1 {
  font-weight: 450;
  font-size: 72px;
  line-height: 75px;
  max-width: 410px;
}
.header {
  // padding: 78px 0 38px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.first-section {
  padding-top: 38px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  h2 {
    font-weight: 450;
    font-size: 38px;
    margin-bottom: 38px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 38px;
  }
  &_left {
    width: 50%;
    max-width: calc(355px + 62px);
    // margin-right: 62px;
    padding-right: 62px;
    &_btn {
      margin-bottom: 38px;
    }
    &_text {
      font-weight: 400 !important;
      font-size: 20px;
      line-height: 140%;
      margin-top: 32px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding-right: 0;
      max-width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.second-section {
  // margin-top: 76px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
  h2 {
    font-weight: 450;
    font-size: 38px;
    margin-bottom: 38px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 26px;
  }
  &_separator {
    margin-bottom: 58px;
  }
  &_img {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.quote {
  background: linear-gradient(90deg, #EFEFEF 9.71%, #FFFFFF 100%);
  border-radius: 31px;
  transform: rotate(180deg);
  margin-bottom: 72px;
  &_content {
    transform: rotate(180deg);
    padding: 64px 48px 64px 42px;
    position: relative;
    p {
      margin-bottom: 14px;
      &.bold {
        font-weight: 500;
        font-size: 24px;
        line-height: 111%;
        color: #464646;
      }
    }
  }
  &_quotes {
    position: absolute;
    top: 26px;
    left: 0;
    font-weight: 450;
    font-size: 96px;
    line-height: 75px;
    color: #9FF4A9;
  }
}
.btn-green {
    padding: 10px 70px !important;
    background: #9FF4A9;
    border: 1px solid #464646;
    border-radius: 50px;
    height: 40px !important;
    &::before {
      opacity: 0;
    }
    &:disabled {
      background: #C4C4C4 !important;
      color: #ACACAC !important;
    }
}
.date {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6B6B6B;
}
.card {
  width: 314px;
  padding: 50px 40px 60px;
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 54px;
  
  &:not(:first-child) {
    margin-left: 54px;
  }
  &_img-container {
    img {
      width: 43px;
      height: 43px;
    }
  }
  &_title {
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
  }
  &_text {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
    // padding-right: 0;
    // max-width: 100%;
  }
}
.subscribe {
  // height: 100px;
  position: relative;
  &::before {
      position: absolute;
      content: "";
      display: block;
      width: 65%;
      height: 100%;
      left: 0;
      background: linear-gradient(90deg, #9FF4A9 0%, rgba(252, 240, 55, 0.33) 100%);
    }
  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 55%;
    height: 100%;
    right: 0;
    background: linear-gradient(270.84deg, #6C6C6C -28.66%, #3F3F3F 93.3%);
    border-top-left-radius: 134px;
    border-bottom-left-radius: 134px;
  }
  @media screen and (max-width: 600px) {
    &::before {
      width:100%;
    }
    &::after {
      display: none;
    }
  }
  &_wrapper {
    width: 100%;
    z-index: 10;
    padding: 70px 50px;
    @media screen and (max-width: 600px) {
      padding: 0;
    }
  }
  .container {
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }
  &_left {
    width: 50%;
    h2 {
      font-weight: 500;
      font-size: 38px;
      line-height: 1;
      margin-bottom: 10px;
      max-width: 60%;
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 0;
      max-width: 55%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 70px 50px 35px;
    }
  }
  &_right {
    width: 50%;
    p {
      font-size: 14px;
      color: #FFFFFF;
    }
    .email-input {
      background: #fff;
      font-weight: 300;
    }
    &::v-deep {
      fieldset {
        border: none;
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 70px 50px 35px;
      background: linear-gradient(270.84deg, #6C6C6C -28.66%, #3F3F3F 93.3%);
    }
  }
}
.latest {
  margin-top: 100px;
  h2 {
    font-weight: 450;
    font-size: 48px;
    line-height: 111.2%;
  }
}
.list-articles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 74px;
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>